import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useAdvancedFilter } from 'pared/Routes/renderer/advancedFilter'
import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationMetricValues: {
    nodes: {
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

interface ICalculatedDataType {
  period: string
  currentValue: number | null
  currentCount: number | null
  priorValue: number | null
  priorCount: number | null
}

const query = gql`
  query trendLocationMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

export const farwestSssConfigs = {
  period: 'string',
  value: 'percent',
  valueLastYear: 'percent',
} as const

const useFarwestSss = () => {
  const { startDate, endDate } = useDateFilter()
  const advancedFilter = useAdvancedFilter('select')
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()

  const groupBy = advancedFilter
    ? advancedFilter.value.timeInterval
    : 'business_month'

  const metrics = advancedFilter?.value.channel

  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy: groupBy,
      iFilter: {
        location_group_ids: !hasGroupBy
          ? [brandLocationGroupId]
          : groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: [
          metrics,
          `${metrics}_same_day_last_year`,
          `${metrics}_same_day_2_years_ago`,
        ],
      },
      intersected_location_group_ids: groupFilter?.intersectedIds,
    },
    skip: !startDate && !endDate && !metrics,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.trendLocationMetricValues.nodes

      if (!metricData || !metrics) return null

      const sameStoreData = metricData?.reduce((calculatedData, rawData) => {
        const thisYearValue = rawData.metricData[metrics].value
        const lastYearValue =
          rawData.metricData[`${metrics}_same_day_last_year`].value
        const twoYearsValue =
          rawData.metricData[`${metrics}_same_day_2_years_ago`].value
        const period =
          groupBy === 'business_month'
            ? `P${rawData.businessMonth}`
            : `P${rawData.businessMonth}W${rawData.businessWeekOfMonth}`

        if (thisYearValue && lastYearValue) {
          const currentValue =
            ((thisYearValue - lastYearValue) / lastYearValue) * 100
          const periodIndex = calculatedData.findIndex(
            (d) => d.period === period,
          )
          if (periodIndex === -1) {
            calculatedData.push({
              period,
              currentValue,
              currentCount: 1,
              priorValue: null,
              priorCount: null,
            })
          } else {
            calculatedData[periodIndex].currentValue = calculatedData[
              periodIndex
            ].currentValue
              ? calculatedData[periodIndex].currentValue + currentValue
              : currentValue

            calculatedData[periodIndex].currentCount = calculatedData[
              periodIndex
            ].currentCount
              ? calculatedData[periodIndex].currentCount + 1
              : 1
          }
        }

        if (lastYearValue && twoYearsValue) {
          const priorValue =
            ((lastYearValue - twoYearsValue) / twoYearsValue) * 100
          const periodIndex = calculatedData.findIndex(
            (d) => d.period === period,
          )
          if (periodIndex === -1) {
            calculatedData.push({
              period,
              currentValue: null,
              currentCount: null,
              priorValue: priorValue,
              priorCount: 1,
            })
          } else {
            calculatedData[periodIndex].priorValue = calculatedData[periodIndex]
              .priorValue
              ? calculatedData[periodIndex].priorValue + priorValue
              : priorValue

            calculatedData[periodIndex].priorCount = calculatedData[periodIndex]
              .priorCount
              ? calculatedData[periodIndex].priorCount + 1
              : 1
          }
        }

        if (!calculatedData.find((d) => d.period === period)) {
          calculatedData.push({
            period,
            currentValue: null,
            currentCount: null,
            priorValue: null,
            priorCount: null,
          })
        }

        return calculatedData
      }, [] as ICalculatedDataType[])

      return sameStoreData
        .map((data) => {
          return {
            period: data.period,
            value:
              data.currentValue && data.currentCount
                ? data.currentValue / data.currentCount
                : null,
            valueLastYear:
              data.priorValue && data.priorCount
                ? data.priorValue / data.priorCount
                : null,
          }
        })
        .filter((d) => d.period)
    }, [data, startDate, endDate]),
    loading,
  }
}

export default useFarwestSss
