import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useAdvancedFilter } from 'pared/Routes/renderer/advancedFilter'
import { useGroupFilter } from 'pared/Routes/renderer/groupFilter'
import { getBrandLocationGroupId } from 'pared/utils/brand'

import { useDateFilter } from '../../../dateFilter'
import { IApiDataType } from '../../types'

interface IDataType {
  trendLocationGroupMetricValues: {
    nodes: {
      businessMonth: number
      businessWeek: number
      businessWeekOfMonth: number
      metricData: Record<
        string,
        {
          name: string
          unit: string
          value: number
        }
      >
    }[]
  }
}

const query = gql`
  query trendLocationGroupMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iGroupBy: String!
    $iFilter: JSON!
  ) {
    trendLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iGroupBy: $iGroupBy
      iFilter: $iFilter
    ) {
      nodes {
        businessMonth
        businessWeek
        businessWeekOfMonth
        metricData
      }
    }
  }
`

export const farwestSalesConfigs = {
  period: 'string',
  value: 'price',
  valueLastYear: 'price',
} as const

const useFarwestSales = () => {
  const { startDate, endDate } = useDateFilter()
  const advancedFilter = useAdvancedFilter('select')
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const brandLocationGroupId = getBrandLocationGroupId()

  const groupBy = advancedFilter
    ? advancedFilter.value.timeInterval
    : 'business_month'

  const metrics = advancedFilter?.value.channel

  const { data, loading } = useQuery<IDataType>(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iGroupBy: groupBy,
      iFilter: {
        location_group_ids: !hasGroupBy
          ? [brandLocationGroupId]
          : groupFilter?.ids,
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: [metrics, `${metrics}_same_day_last_year`],
      },
    },
    skip: !startDate && !endDate && !metrics,
  })

  return {
    data: useMemo((): IApiDataType => {
      const metricData = data?.trendLocationGroupMetricValues.nodes

      if (!metricData || !metrics) return null

      return metricData
        .map((data) => {
          return {
            period:
              groupBy === 'business_month'
                ? `P${data.businessMonth}`
                : `P${data.businessMonth}W${data.businessWeekOfMonth}`,
            value: data.metricData[metrics].value * 100,
            valueLastYear:
              data.metricData[`${metrics}_same_day_last_year`].value * 100,
          }
        })
        .filter((d) => d.period)
    }, [data, startDate, endDate]),
    loading,
  }
}

export default useFarwestSales
