import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../dateFilter'
import { useGroupFilter } from '../../groupFilter'
import { IApiDataType } from '../types'

const query = gql`
  query ListFarwestFlashKpis(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
    $hasGroupBy: Boolean!
  ) {
    listLocationGroupMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @skip(if: $hasGroupBy) {
      nodes {
        locationGroupId
        metricData
        metricSummaryData
      }
    }

    listLocationMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
    ) @include(if: $hasGroupBy) {
      nodes {
        locationId
        metricData
        metricSummaryData
      }
    }

    listLocationMarketAndOpenDate(iFilter: $iFilter) @include(if: $hasGroupBy) {
      nodes {
        locationId
        openedAt
        market
        ro
      }
    }
  }
`

export const farwestCustomizedFlashConfigs = {
  //daily_ops
  salesForecast: 'price',
  salesActual: 'price',
  salesVariance: 'price',
  spmh: 'price',
  laborScheduledHours: 'number',
  laborActualHours: 'number',
  laborVarianceHours: 'number',
  otScheduledHours: 'number',
  otActualHours: 'number',
  laborScheduledPercent: 'percent',
  laborActualPercent: 'percent',
  laborVariancePercent: 'percent',
  cashDeposit: 'price',
  cashOs: 'price',
  totalCards: 'price',
  paidIn: 'price',
  paidOut: 'price',
  checkAverage: 'price',
  refund: 'price',
  voidSales: 'price',
  comps: 'price',
  promos: 'price',
  netSales: 'price',
  classicCaseCount: 'number',
  tendersCaseCount: 'number',
  bonelessCaseCount: 'number',

  //flash_weekly_labor_tracker
  netSalesWtd: 'price',
  comparableNetSalesWtdLy: 'price',
  netSalesWtdPercentageDiffToLastYear: 'percent',
  gmMatrixSales: 'price',
  amMatrixSales: 'price',
  cashierMatrixSales: 'price',
  cookMatrixSales: 'price',
  shiftMatrixSales: 'price',
  totalMatrixSales: 'price',
  gmMatrixSalesPercentage: 'percent',
  amMatrixSalesPercentage: 'percent',
  cashierMatrixSalesPercentage: 'percent',
  cookMatrixSalesPercentage: 'percent',
  shiftMatrixSalesPercentage: 'percent',
  totalMatrixSalesPercentage: 'percent',
  gmTotalWage: 'price',
  amTotalWage: 'price',
  cashierTotalWage: 'price',
  cookTotalWage: 'price',
  shiftTotalWage: 'price',
  totalWage: 'price',
  gmTotalWagePercentage: 'percent',
  amTotalWagePercentage: 'percent',
  cashierTotalWagePercentage: 'percent',
  cookTotalWagePercentage: 'percent',
  shiftTotalWagePercentage: 'percent',
  totalWagePercentage: 'percent',
  gmMatrixHours: 'number',
  amMatrixHours: 'number',
  cashierMatrixHours: 'number',
  cookMatrixHours: 'number',
  shiftMatrixHours: 'number',
  totalMatrixHours: 'number',
  gmLaborActualHours: 'number',
  amLaborActualHours: 'number',
  cashierLaborActualHours: 'number',
  cookLaborActualHours: 'number',
  shiftLaborActualHours: 'number',
  totalLaborActualHours: 'number',
  gmLaborOtHours: 'number',
  amLaborOtHours: 'number',
  cashierLaborOtHours: 'number',
  cookLaborOtHours: 'number',
  shiftLaborOtHours: 'number',
  totalLaborOtHours: 'number',
  gmLaborTotalHours: 'number',
  amLaborTotalHours: 'number',
  cashierLaborTotalHours: 'number',
  cookLaborTotalHours: 'number',
  shiftLaborTotalHours: 'number',
  laborTotalHours: 'number',
  gmLaborHoursVariance: 'number',
  amLaborHoursVariance: 'number',
  cashierLaborHoursVariance: 'number',
  cookLaborHoursVariance: 'number',
  shiftLaborHoursVariance: 'number',
  totalLaborHoursVariance: 'number',
  laborHoursVariance: 'number',
  cookTrainingHours: 'number',
  shiftLeaderTrainingHours: 'number',
  amTrainingHours: 'number',
  gmTrainingHours: 'number',
  cashierTrainingHours: 'number',
  fieldTrainerTrainingHours: 'number',
  dmTrainingHours: 'number',
  totalTrainingHours: 'number',
  cookTrainingCost: 'price',
  shiftLeaderTrainingCost: 'price',
  amTrainingCost: 'price',
  gmTrainingCost: 'price',
  cashierTrainingCost: 'price',
  fieldTrainerTrainingCost: 'price',
  dmTrainingCost: 'price',
  totalTrainingCost: 'price',
  cookTrainingCostPercent: 'percent',
  shiftLeaderTrainingCostPercent: 'percent',
  amTrainingCostPercent: 'percent',
  gmTrainingCostPercent: 'percent',
  cashierTrainingCostPercent: 'percent',
  fieldTrainerTrainingCostPercent: 'percent',
  dmTrainingCostPercent: 'percent',
  totalTrainingCostPercent: 'percent',

  //flash_sales_and_ticket_comp_report
  market: 'string',
  ro: 'string',
  openAt: 'date-string',
  //netSales: 'price',  // duplicate
  netSalesSameDayLastYear: 'price',
  netSalesSameDay2YearsAgo: 'price',
  netSalesTrailing7Days: 'price',
  netSalesTrailing7DaysLastYear: 'price',
  averageNetSalesTrailing7Days: 'price',
  averageNetSalesTrailing7DaysLastYear: 'price',
  netSalesYtd: 'price',
  netSalesTtm: 'price',
  netSalesPercentageDiffToLastYear: 'percent',
  netSalesPercentageDiffTo2YearsAgo: 'percent',
  netSales7DaysAgo: 'price',
  netSalesPercentageDiffTo7DaysAgo: 'percent',
  averageNetSalesTrailing7DaysPercentageDiffToLastYear: 'percent',
  netSalesLastWeek: 'price',
  netSalesLastWeekLastYear: 'price',
  netSalesLastWeekPercentageDiffToLastYear: 'percent',
  netSalesPtd: 'price',
  netSalesPtdLastYear: 'price',
  netSalesPtdPercentageDiffToLastYear: 'percent',
  netSalesTrailing30Days: 'price',
  netSalesTrailing30DaysLastYear: 'price',
  netSalesTrailing30DaysPercentageDiffToLastYear: 'percent',
  netSalesYtdLastYear: 'price',
  netSalesYtdPercentageDiffToLastYear: 'percent',
  checkCount: 'number',
  checkCountSameDayLastYear: 'number',
  checkCountSameDay2YearsAgo: 'number',
  checkCountPercentageDiffToLastYear: 'percent',
  checkCountPercentageDiffTo2YearsAgo: 'percent',
  checkCount7DaysAgo: 'number',
  checkCountPercentageDiffTo7DaysAgo: 'percent',
  checkCountTrailing7Days: 'number',
  checkCountTrailing7DaysLastYear: 'number',
  averageCheckCountTrailing7DaysPercentageDiffToLastYear: 'percent',
  checkCountPtd: 'number',
  checkCountPtdLastYear: 'number',
  checkCountWtdPercentageDiffToLastYear: 'percent',
  checkCountPtdPercentageDiffToLastYear: 'percent',
  checkCountTrailing30Days: 'number',
  checkCountTrailing30DaysLastYear: 'number',
  checkCountTrailing30DaysPercentageDiffToLastYear: 'percent',
  checkCountYtd: 'number',
  checkCountYtdLastYear: 'number',
  checkCountYtdPercentageDiffToLastYear: 'percent',
  // checkAverage: 'price',  // duplicate
  checkAverageLastWeek: 'price',
  checkAverageSameDayLastYear: 'price',
  checkAverageSameDay2YearsAgo: 'price',
  checkAveragePercentageDiffToLastYear: 'percent',
  checkAveragePercentageDiffTo2YearsAgo: 'percent',
  checkAveragePercentageDiffTo7DaysAgo: 'percent',
  checkAverageTrailing7DaysPercentageDiffToLastYear: 'percent',
  checkAverageWtdPercentageDiffToLastYear: 'percent',
  checkAveragePtdPercentageDiffToLastYear: 'percent',
  checkAverageTrailing30DaysPercentageDiffToLastYear: 'percent',
  checkAverageYtdPercentageDiffToLastYear: 'percent',
  checkCountLastWeek: 'number',
  checkCountLastWeekLastYear: 'number',
  checkCountLastWeekPercentageDiffToLastYear: 'percent',
  checkAverageLastWeekPercentageDiffToLastYear: 'percent',
  //netSalesWtd: 'price',   // duplicate
  //comparableNetSalesWtdLy: 'price',  // duplicate
  checkAverage7DaysAgo: 'price',
  netSalesQtd: 'price',
  checkCountQtd: 'number',
  checkAverageQtd: 'price',
  netSalesQtdPercentageDiffToLastYear: 'percent',
  checkCountQtdPercentageDiffToLastYear: 'percent',
  checkAverageQtdPercentageDiffToLastYear: 'percent',
} as const

const KPIS = [
  //daily_ops
  'sales_forecast',
  'sales_actual',
  'sales_variance',
  'spmh',
  'labor_scheduled_hours',
  'labor_actual_hours',
  'labor_variance_hours',
  'ot_scheduled_hours',
  'ot_actual_hours',
  'labor_scheduled_percent',
  'labor_actual_percent',
  'labor_variance_percent',
  'cash_deposit',
  'cash_os',
  'total_cards',
  'paid_in',
  'paid_out',
  'check_average',
  'refund',
  'void_sales',
  'comps',
  'promos',
  'net_sales',
  'classic_case_count',
  'tenders_case_count',
  'boneless_case_count',

  //flash_weekly_labor_tracker
  'net_sales_wtd',
  'net_sales_wtd_last_year',
  'net_sales_wtd_percentage_diff_to_last_year',
  'gm_matrix_sales',
  'am_matrix_sales',
  'cashier_matrix_sales',
  'cook_matrix_sales',
  'shift_matrix_sales',
  'total_matrix_sales',
  'gm_matrix_sales_percentage',
  'am_matrix_sales_percentage',
  'cashier_matrix_sales_percentage',
  'cook_matrix_sales_percentage',
  'shift_matrix_sales_percentage',
  'total_matrix_sales_percentage',
  'gm_total_wage',
  'am_total_wage',
  'cashier_total_wage',
  'cook_total_wage',
  'shift_total_wage',
  'total_wage',
  'gm_total_wage_percentage',
  'am_total_wage_percentage',
  'cashier_total_wage_percentage',
  'cook_total_wage_percentage',
  'shift_total_wage_percentage',
  'total_wage_percentage',
  'gm_matrix_hours',
  'am_matrix_hours',
  'cashier_matrix_hours',
  'cook_matrix_hours',
  'shift_matrix_hours',
  'total_matrix_hours',
  'gm_labor_actual_hours',
  'am_labor_actual_hours',
  'cashier_labor_actual_hours',
  'cook_labor_actual_hours',
  'shift_labor_actual_hours',
  'total_labor_actual_hours',
  'gm_labor_ot_hours',
  'am_labor_ot_hours',
  'cashier_labor_ot_hours',
  'cook_labor_ot_hours',
  'shift_labor_ot_hours',
  'total_labor_ot_hours',
  'gm_labor_total_hours',
  'am_labor_total_hours',
  'cashier_labor_total_hours',
  'cook_labor_total_hours',
  'shift_labor_total_hours',
  'labor_total_hours',
  'gm_labor_hours_variance',
  'am_labor_hours_variance',
  'cashier_labor_hours_variance',
  'cook_labor_hours_variance',
  'shift_labor_hours_variance',
  'total_labor_hours_variance',
  'labor_hours_variance',
  'cook_training_hours',
  'shift_leader_training_hours',
  'am_training_hours',
  'gm_training_hours',
  'cashier_training_hours',
  'field_trainer_training_hours',
  'dm_training_hours',
  'total_training_hours',
  'cook_training_cost',
  'shift_leader_training_cost',
  'am_training_cost',
  'gm_training_cost',
  'cashier_training_cost',
  'field_trainer_training_cost',
  'dm_training_cost',
  'total_training_cost',
  'field_trainer_training_hours_percent',
  'dm_training_hours_percent',
  'cook_training_cost_percent',
  'shift_leader_training_cost_percent',
  'am_training_cost_percent',
  'gm_training_cost_percent',
  'cashier_training_cost_percent',
  'field_trainer_training_cost_percent',
  'dm_training_cost_percent',
  'total_training_cost_percent',

  //  flash_sales_and_ticket_comp_report
  //  'net_sales',
  'net_sales_same_day_last_year',
  'net_sales_same_day_2_years_ago',
  'net_sales_trailing_7_days',
  'net_sales_trailing_7_days_last_year',
  'average_net_sales_trailing_7_days',
  'average_net_sales_trailing_7_days_last_year',
  'net_sales_ytd',
  'net_sales_ttm',
  'net_sales_percentage_diff_to_last_year',
  'net_sales_percentage_diff_to_2_years_ago',
  'net_sales_7_days_ago',
  'net_sales_percentage_diff_to_7_days_ago',
  'average_net_sales_trailing_7_days_percentage_diff_to_last_year',
  'net_sales_last_week',
  'net_sales_last_week_last_year',
  'net_sales_last_week_percentage_diff_to_last_year',
  'net_sales_ptd',
  'net_sales_ptd_last_year',
  // 'net_sales_wtd_percentage_diff_to_last_year',
  'net_sales_ptd_percentage_diff_to_last_year',
  'net_sales_trailing_30_days',
  'net_sales_trailing_30_days_last_year',
  'net_sales_trailing_30_days_percentage_diff_to_last_year',
  'net_sales_ytd_last_year',
  'net_sales_ytd_percentage_diff_to_last_year',
  'check_count',
  'check_count_same_day_last_year',
  'check_count_same_day_2_years_ago',
  'check_count_percentage_diff_to_last_year',
  'check_count_percentage_diff_to_2_years_ago',
  'check_count_7_days_ago',
  'check_count_percentage_diff_to_7_days_ago',
  'check_count_trailing_7_days',
  'check_count_trailing_7_days_last_year',
  'average_check_count_trailing_7_days_percentage_diff_to_last_year',
  'check_count_ptd',
  'check_count_ptd_last_year',
  'check_count_wtd_percentage_diff_to_last_year',
  'check_count_ptd_percentage_diff_to_last_year',
  'check_count_trailing_30_days',
  'check_count_trailing_30_days_last_year',
  'check_count_trailing_30_days_percentage_diff_to_last_year',
  'check_count_ytd',
  'check_count_ytd_last_year',
  'check_count_ytd_percentage_diff_to_last_year',
  //'check_average',
  'check_average_last_week',
  'check_average_same_day_last_year',
  'check_average_same_day_2_years_ago',
  'check_average_percentage_diff_to_last_year',
  'check_average_percentage_diff_to_2_years_ago',
  'check_average_percentage_diff_to_7_days_ago',
  'check_average_trailing_7_days_percentage_diff_to_last_year',
  'check_average_wtd_percentage_diff_to_last_year',
  'check_average_ptd_percentage_diff_to_last_year',
  'check_average_trailing_30_days_percentage_diff_to_last_year',
  'check_average_ytd_percentage_diff_to_last_year',
  'check_count_last_week',
  'check_count_last_week_last_year',
  'check_count_last_week_percentage_diff_to_last_year',
  'check_average_last_week_percentage_diff_to_last_year',
  //'net_sales_wtd',
  'comparable_net_sales_wtd_ly',
  'check_count_7_days_ago',
  'check_average_7_days_ago',
  'net_sales_qtd',
  'check_count_qtd',
  'check_average_qtd',
  'net_sales_qtd_percentage_diff_to_last_year',
  'check_count_qtd_percentage_diff_to_last_year',
  'check_average_qtd_percentage_diff_to_last_year',
]

const useFarwestCustomizedFlash = () => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter, hasGroupBy } = useGroupFilter()
  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iFilter: {
        location_group_ids: hasGroupBy
          ? groupFilter?.ids
          : groupFilter?.list?.map((g) => g.id),
        intersected_location_group_ids: groupFilter?.intersectedIds,
        metrics: KPIS,
      },
      hasGroupBy,
    },
    skip: !startDate || !endDate || !groupFilter,
  })

  return {
    data: useMemo((): IApiDataType => {
      const customizedData: any =
        data?.[
          hasGroupBy
            ? 'listLocationMetricValues'
            : 'listLocationGroupMetricValues'
        ]?.nodes
      const locationDetails: any = data?.listLocationMarketAndOpenDate?.nodes

      if (!customizedData) return null

      const source = customizedData.map((locationData: any) => {
        const groupInfo = groupFilter?.list?.find(
          (l) =>
            l.id ===
            (hasGroupBy
              ? locationData.locationId
              : locationData.locationGroupId),
        )
        const marketDetail = locationDetails?.find(
          (l: any) => l.locationId === locationData.locationId,
        )
        const kpisData: { [key: string]: number | null } = {}

        for (const key in locationData.metricData) {
          if (locationData.metricData.hasOwnProperty(key)) {
            const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
            switch (locationData.metricData[key].unit) {
              case 'DOLLAR':
              case 'PERCENTAGE':
                kpisData[newKey] =
                  locationData.metricData[key].value === null
                    ? null
                    : locationData.metricData[key].value * 100
                break

              default:
                kpisData[newKey] = locationData.metricData[key].value
                break
            }
          }
        }

        return {
          ...kpisData,
          openAt: marketDetail?.openedAt,
          market: marketDetail?.market,
          ro: marketDetail?.ro,
          groupInfo,
        }
      })

      const [rawSummary] = customizedData.filter(
        (locationData: any) => locationData.metricSummaryData != null,
      )
      const summary: { [key: string]: number | null } = {}
      for (const key in rawSummary?.metricSummaryData) {
        if (rawSummary.metricSummaryData.hasOwnProperty(key)) {
          const newKey = key.replace(/_(.)/g, (_, char) => char.toUpperCase())
          switch (rawSummary.metricSummaryData[key].unit) {
            case 'DOLLAR':
            case 'PERCENTAGE':
              summary[newKey] =
                rawSummary.metricSummaryData[key].value === null
                  ? null
                  : rawSummary.metricSummaryData[key].value * 100
              break

            default:
              summary[newKey] = rawSummary.metricSummaryData[key].value
              break
          }
        }
      }

      return {
        source,
        summary: {
          ...summary,
          openAt: '',
          market: '',
        },
      }
    }, [groupFilter, data]),
    loading,
  }
}

export default useFarwestCustomizedFlash
